import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'detailsAssetItems',
  components: {
    appStrings,
    DatePicker,
    commonHelper
  },
  props: ['rowData','saveFlag','getassetMasterItems'],
  data() {
    return {
      loader: false,
      assetItemName: null,
      assetItemType: {
        text: null,
        value: null
      },
      assetItemCode:null,
      responseMsg: '',
      isSuccess: false,
      showAlert: false,
      payload: null,
      detailsAssetMasterItemData: [
        {
          item_filters: 'Serial Number',
          key: 'serial_number'
        },
        {
          item_filters: 'Wireless Mac Address',
          key: 'wireless_mac_address'
        },
        {
          item_filters: 'Lan Mac Address',
          key: 'lan_mac_address'
        },
        { item_filters: 'Notes', key: 'notes' },
        { item_filters: 'Tags', key: 'tags' },
        { item_filters: 'Model', key: 'model' },
        { item_filters: 'Color', key: 'color' },
        { item_filters: 'Manufacturer', key: 'manufacturer' },
        { item_filters: 'Department', key: 'department' },
        { item_filters: 'Processor', key: 'processor' },
      ],
      detailsAssetMasterItemFields: [
        {
          key: 'item_filters',
          label: 'Mandatory /Non-mandatory fields',
          class: "boxSize"
        },
        {
          key: 'status'
        },
      ],
      showValueSetModal: false,
      editMode: false,
      nameFlag: false,
      active: true
    };
  },
  validations: {
    assetItemName: { required },
    assetItemType:{
      text: { required },
    },
    assetItemCode:{required}
  },
  mounted() {
    this.getData(this.rowData);
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          //   this.showHideAddFaLocationModal(true);
        } else if (actionName === 'update' || actionName === 'edit') {
          this.editMode = true;
        } else if (actionName === 'save') {
          if(this.saveFlag){
            this.postassetMasterItems()
          }else{
            this.putassetMasterItems()
          }
        }
      }
    });
  },
  methods: {
  /*Below function populates data on "Item Details" modal when rowData is obtained from main 
  grid table for specific row*/
    getData(rowData) {
      if (rowData) {
        this.assetItemName = rowData.item_name;
        this.assetItemType.text = rowData.item_type;
        this.assetItemType.value = rowData.item_type;
        this.assetItemCode = rowData.item_code;
        if (rowData.active == 'ACTIVE') {
          this.active = true;
        } else {
          this.active = false;
        }
        this.nameFlag = false;
        for (let i in rowData) {
          for (let j = 0; j < this.detailsAssetMasterItemData.length; j++) {
            if (i === this.detailsAssetMasterItemData[j].key) {
                this.detailsAssetMasterItemData[j].status = rowData[i];
            }
          }
        }
      } else {
        this.editMode = true;
        this.nameFlag = true;
      }
    },
//Below function is used to open valueset modal 
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.specIndex = index;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
//Below function sets values for particular variables for which valueset modal is opened
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.MANDATORY_STATUS_VSET) {
        this.detailsAssetMasterItemData[this.specIndex].status =
          item.value_code;
      }
      else if(this.vsetCode === appStrings.VALUESETTYPE.FA_ITEM_TYPE){
          this.assetItemType.text=item.value_meaning;
          this.assetItemType.value=item.value_code;
      }
    },
//Below function closes valueset modal
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
//Below function adds new asset item data using POST API
    postassetMasterItems() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
      const payload = {
        active: this.active,
        item_code: this.assetItemCode,
        item_id: 0,
        item_name: this.assetItemName,
        item_type:this.assetItemType.value,
        serial_number: this.detailsAssetMasterItemData[0].status? this.detailsAssetMasterItemData[0].status:false,
        mac_address_wireless: this.detailsAssetMasterItemData[1].status?this.detailsAssetMasterItemData[1].status:false,
        mac_address_wired: this.detailsAssetMasterItemData[2].status?this.detailsAssetMasterItemData[2].status:false,
        notes: this.detailsAssetMasterItemData[3].status?this.detailsAssetMasterItemData[3].status:false,
        tags: this.detailsAssetMasterItemData[4].status?this.detailsAssetMasterItemData[4].status:false,
        model: this.detailsAssetMasterItemData[5].status?this.detailsAssetMasterItemData[5].status:false,
        color: this.detailsAssetMasterItemData[6].status?this.detailsAssetMasterItemData[6].status:false,
        manufacturer: this.detailsAssetMasterItemData[7].status?this.detailsAssetMasterItemData[7].status:false,
        department: this.detailsAssetMasterItemData[8].status?this.detailsAssetMasterItemData[8].status:false,
        processor:  this.detailsAssetMasterItemData[9].status?this.detailsAssetMasterItemData[9].status:false
      };
      this.loader = true;
      this.$store
        .dispatch('assets/postAssetMasterItem', payload)
        .then(resp => {
          this.loader = false;
          this.showAlert = true;
          if (resp.status === 200) {
            this.isSuccess = true;
            this.responseMsg=resp.data.message;
            this.getassetMasterItems()
          }else{
            this.isSuccess = false;
            this.responseMsg=resp.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
      }
    },
//Below function updates already existing asset items data using PUT API
    putassetMasterItems() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          active: this.active,
          item_code: this.assetItemCode,
          item_id: this.rowData.item_id,
          item_name: this.assetItemName,
          item_type:this.assetItemType.value,
          serial_number: this.detailsAssetMasterItemData[0].status,
          mac_address_wireless: this.detailsAssetMasterItemData[1].status,
          mac_address_wired: this.detailsAssetMasterItemData[2].status,
          notes: this.detailsAssetMasterItemData[3].status,
          tags: this.detailsAssetMasterItemData[4].status,
          model: this.detailsAssetMasterItemData[5].status,
          color: this.detailsAssetMasterItemData[6].status,
          manufacturer: this.detailsAssetMasterItemData[7].status,
          department: this.detailsAssetMasterItemData[8].status,
          processor: this.detailsAssetMasterItemData[9].status
        };
        this.loader = true;
        this.$store
          .dispatch('assets/putAssetMasterItem', payload)
          .then(resp => {
            this.loader = false;
            this.showAlert=true;
            if (resp.status === 200) {
              this.isSuccess = true;
              this.responseMsg=resp.data.message;
              this.getassetMasterItems()  
            }else{
              this.isSuccess = false;
              this.responseMsg=resp.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
        }
      },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
