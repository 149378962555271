import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import detailsAssetMasterItems from './detailsAssetMasterItems';

export default {
  name: 'assetItems',
  components: {
    appStrings,
    DatePicker,
    detailsAssetMasterItems
  },
  watch: {
    currentPage: function() {
      this.getassetMasterItems();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getassetMasterItems();
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      assetItemName: null,
      assetItemType: {
        text: null,
        value: null
      },
      assetItemCode:null,
      showValueSetModal:null,
      showHideAssetMasterItemsFlag: false,
      responseMsg: '',
      isSuccess: false,
      assetMasterItemsData: [],
      assetMasterItemsFields: [
        {
          key: 'item_code',
          label:'Category Code'
        },
        {
          key: 'item_name',
          label:'Category Name'
        },
        {
          key: 'active',
          label:'Status'
        },
        {
          key: 'item_type',
        },
        {
          key: 'created_by_user',
          label:"Created By"
        },
        {
          key:'creation_date'
        }
      ],
      showAlert: false,
      rowData:null,
      editMode:false,
      saveFlag:false,
      active:true,
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.rowData=null;
          this.saveFlag=true;
          this.showHideAssetMasterItemsModal(true);
        }else if (actionName === 'save' && !this.showHideAssetMasterItemsFlag) {
            // this.saveassetMasterItems()
          }
      }
    });
  },
  methods: {
//Below function is used to get data thru GET API by using search button based on search filter inputs
    getassetMasterItems() {
      const payload = {
      //  itemId:this.assetMasterItemsData.item_id,
       page: this.currentPage - 1,
       limit: this.perPage,
       item_code:this.assetItemCode,
       item_name: this.assetItemName,
       item_type:this.assetItemType.value,
       active:this.active
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAssetMasterItem', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.assetMasterItemsData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
            this.assetMasterItemsData.map(item=>{
              if(item.active==true){
                item.active="ACTIVE"
              }else{
                item.active="INACTIVE"
              }
            })
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
//Below function is used to clear data by using clear button
    clearData() {
      this.assetItemName= null,
      this.assetItemType.text= null,
      this.assetItemType.value= null,
      this.assetItemCode=null,
      this.totalRows = null;
      this.currentPage = 1;
      this.assetMasterItemsData=null;
      this.active=false;
    },
/*Below function is used to open "Items Details" modal while clicked on a specific row 
and pass on the data of that specific row to the modal*/
  rowSelected(item) {
      this.rowData=item;
      this.saveFlag=false;
      this.showHideAssetMasterItemsFlag=!this.showHideAssetMasterItemsFlag;
    },
//Below function is used to open "Items Details" modal while clicked on "Add" button
    showHideAssetMasterItemsModal(flag) {
      this.showHideAssetMasterItemsFlag = flag;
    },
//Below function is used to open valueset modal  
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.specIndex = index;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null,
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
//Below function sets values for particular variables for which valueset modal is opened
    selectedvalueSet(item) {
      if(this.vsetCode === appStrings.VALUESETTYPE.FA_ITEM_TYPE){
          this.assetItemType.text=item.value_meaning;
          this.assetItemType.value=item.value_code;
      }
    },
//Below function closes valueset modal 
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
//Below function is used to clear the values of variables which are set thru valuesets
    clearVsetValues(vsetCode) {
      this.vsetCode=vsetCode
      if(this.vsetCode===appStrings.VALUESETTYPE.FA_ITEM_TYPE){
        this.assetItemType.text= null;
        this.assetItemType.value= null;
      }
    },
}
, 
beforeDestroy() {
  this.unsubscribe();
}
};
